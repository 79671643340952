import MDinput from '@/components/MDinput';
import PermissionSelect from './PermissionSelect';
const PermissionPrefix = 'permission_';
import { validatorRequire } from '@/utils/validators';

export function getFormDefinition(vue) {
  const definition = {
    id: {
      type: null,
      defaultValue: undefined
    },
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true
      }
    }
  };

  vue.$store.getters['permission/loadedItems'].forEach(permission => {
    definition[PermissionPrefix + permission.id] = {
      type: PermissionSelect,
      defaultValue: 'n',
      props: {
        caption: 'permission.' + permission.code,
        maxlength: 1,
        required: true
      }
    };
  });

  return definition;
}

export function createItemFromFormData(vue, form) {
  const role = {
    name: form.name,
    description: form.description,
    domainID: vue.$store.getters.domainID,
    rolePermissions: [],
    roleTaskTypes: form.roleTaskTypes
  };
  if (form.id) {
    role['id'] = form.id;
  }
  Object.keys(form).forEach(field => {
    if (field.startsWith(PermissionPrefix)) {
      const permissionLevel = form[field];
      if (permissionLevel && permissionLevel.match(new RegExp('r|w|x'))) {
        const permissionID = field.replace(/\D/g, '');
        const permission = vue.$store.getters['permission/getLoadedItemByID'](permissionID);
        const rolePermission = {
          permissionID: permission.id,
          code: permission.code,
          description: permission.description,
          permissionLevel: permissionLevel
        };
        role.rolePermissions.push(rolePermission);
      }
    }
  });
  return role;
}

export function createFormDataFromItem(vue, role) {
  const form = {
    ...role
  };
  delete form.rolePermissions;
  role.rolePermissions.forEach(permission => {
    form[PermissionPrefix + permission.permissionID] = permission.permissionLevel;
  });
  return form;
}
